<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "shaDu",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "windows安全中心和第三方杀毒软件说明",
                        text:[
                            '玩家电脑一般两种情况: 1,没有安装任何杀毒软件,使用windows安全中心, 2,安装了360,电脑管家,火绒等第三方杀毒软件',
                            '为什么说两种情况? 因为安装了第三方杀毒后会自动接管windows安全中心的工作,windows安全中心就停止工作了,所以' +
                            '简单说就是有第三方杀毒和没第三方杀毒两种情况',
                            '两者区别:"windows安全中心"保护能力更强,任何微软数据库没有的文件一律删除屏蔽' +
                            '(助手不在微软的数据里,所以会被删除), "第三方杀毒"同样保护,但是对于一些保护可以设置,例如把' +
                            '助手加入"白名单/信任区"等操作',
                            '最佳建议:安装第三方杀毒,然后把助手加入白名单,即能保护电脑也不影响助手使用'
                        ],
                        img:[]
                    },
                    {
                        title: "'第三方杀毒软件'如何设置",
                        text:[
                            '#以"腾讯电脑管家"举例',
                            '#打开右上角设置-高级设置-手动处理,图1所示',
                            '#自动添加信任: 在下载使用助手时候,弹出对话框(图2),点击信任(图3),即可完成杀毒的设定',
                            '*建议设置自动添加即可,在有提示的时候加入信任,不提示无需操作!',
                            '#手动添加信任: 在下载好助手并解压后,在主界面点击"信任区",然后按图4图5图6操作即可',
                        ],
                        img:[
                            "1.png",
                            "2.png",
                            "3.png",
                            "4.png",
                            "5.png",
                            "6.png",
                        ]
                    },
                    {
                        title: "'windows安全中心'如何设置",
                        text:[
                            '#鼠标移动到"windows图标","右键",弹出菜单选择"设置"图1',
                            '#选择"windows安全中心", 点击"打开windows安全中心"图2',
                            '#点击图3病毒和威胁防护',
                            '#点击图4管理设置',
                            '#把四个选项全部关闭,图5',
                            '#以上便关闭了"windows安全中心"',
                        ],
                        img:[
                            "7.png",
                            "8.png",
                            "9.png",
                            "10.png",
                            "11.png",
                        ]
                    },
                    {
                        title: "如果'windows安全中心'关闭后又自动打开怎么办",
                        text:[
                            '#浏览器打开网址"https://www.ookan.com/安全中心控制器.rar",下载安全中心控制器并解压到桌面',
                            '#管理员运行后点击关闭安全中心图1图2',
                            '*注意:需要上一步关闭安全中心后再运行此工具,否则此工具也会被删除拦截',
                        ],
                        img:[
                            "12.png",
                            "13.png",
                        ]
                    },

                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
